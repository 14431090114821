import React from "react"

const ComponentTextblock1 = ({ title, subtitle, content }) => {
  return (
    <div className="flex-row">
      <div className="flex-column-7">
        <h1 className="h1">{title}</h1>
        <p className="message-label-bold txt txt-rot">{subtitle}</p>
        <p>{content}</p>
      </div>
    </div>
  )
}

export default ComponentTextblock1
