import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
//import { graphql } from "gatsby";
//import { StaticImage } from "gatsby-plugin-image"
import ComponentTextblock1 from "../components/component-textblock-1";
import { useIntl } from "gatsby-plugin-react-intl";
import moment from 'moment-timezone/builds/moment-timezone-with-data';
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import HubspotForm from '../components/HubspotForm';

const PageContact = ({ data, location }) => {
    const intl = useIntl();
    const [value, setValue] = React.useState({ country: "Germany" });
    const [serverResponse, setServerResponse] = React.useState("");
    //const moment = require("moment");

    function handleChange(e) {
        value[e.target.name] = e.target.value;
        setServerResponse("");
        setValue({ ...value });
    }

    const portalId = "25946107"
    const formGuid = "a23f47a0-9b90-4628-91f3-14fbe6376181"

    const datetime = moment().tz('Europe/Berlin').format("YYYY-MM-DD HH:mm")

    let payload = {
        "fields": [
            { "objectTypeId": "0-1", "name": "firstname", "value": value.first_name ? value.first_name : "null" },
            { "objectTypeId": "0-1", "name": "lastname", "value": value.last_name ? value.last_name : "null" },
            { "objectTypeId": "0-1", "name": "email", "value": value.email ? value.email : "null" },
            { "objectTypeId": "0-1", "name": "company", "value": value.company ? value.company : "null" },
            { "objectTypeId": "0-1", "name": "reason_of_inquiry", "value": value.reason ? value.reason : "null" },
            { "objectTypeId": "0-1", "name": "website2", "value": value.website ? value.website : "null" },
            { "objectTypeId": "0-1", "name": "zip", "value": value.zip ? value.zip : "null" },
            { "objectTypeId": "0-1", "name": "city", "value": value.city ? value.city : "null" },
            { "objectTypeId": "0-1", "name": "country", "value": value.country ? value.country : "null" },
            { "objectTypeId": "0-1", "name": "state", "value": value.state ? value.state : "null" },
            { "objectTypeId": "0-1", "name": "which_best_describes_you_", "value": value.describes ? value.describes : "null" },
            { "objectTypeId": "0-1", "name": "submit_date", "value": datetime },
        ]
    }
    async function onSubmit(e) {
        e.preventDefault();

        //Send data to GTM, GA4
        console.log("Start sending event...")
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: "formSubmit" });
            console.log("Event sent!")
        }

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/' + portalId + '/' + formGuid, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error('通信に失敗しました', error);
            });
        const response = await window
            .fetch("/api/form", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(value),
            })
            .then((res) => res.json());
        setServerResponse(response);
    }

    return (
        <Layout location={location}>
            <Seo
                title={
                    intl.locale === "en"
                        ? `Contact us - How to contact Cyberdyne`
                        : `Kontaktieren Sie uns - Wie Sie Cyberdyne kontaktieren können`
                }
                description={
                    intl.locale === "en"
                        ? `Contact Cyberdyne support by email or phone. Get in touch with us! Available from 8:30am-17:00pm.`
                        : `Kontaktieren Sie Cyberdyne per E-Mail oder Telefon. Treten Sie in Kontakt mit uns, wir sind von 8:30-17:30 Uhr erreichbar.`
                }
                ogImage="https://images.ctfassets.net/t2ifj85iosf3/Gx3Sc0gyL2KygjlYoItex/6e87f29d8f2062cd9c58ba8cbb82fbfc/E169.jpg?w=1200&h=630&fit=fill"
            />
            <section className="sect">
                <div className="container">
                    <ComponentTextblock1
                        title={
                            intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`
                        }
                        subtitle={intl.locale === "en" ? `Inquiry` : `Anfrage`}
                        content={
                            intl.locale === "en"
                                ? `Thank you for your interest in Cyberdyne. If you can’t find the information you need on our website, please email us using the form below. Alternatively, you can call us on: +49 0234 5873007008, Monday to Friday from 8.30am-5pm`
                                : `Vielen Dank für Ihr Interesse an Cyberdyne. Sollten Sie die gesuchten Informationen nicht auf unserer Internetseite finden, nutzen Sie gerne das untenstehende Formular, um uns eine E-Mail zu schreiben. Montags - Freitags von 8.30 - 17.00 Uhr können Sie uns gerne auch telefonisch unter folgender Nummer anrufen: +49 0234 5873007008 `
                        }
                    />
                </div>
            </section>
            <section className="sect sect_Product__catch4 sect_Product__catch5 p-0 gradient">
                <div className="sect_Product__catch___2">
                    <div className="">
                        <div className="container">
                            <div className="container-bg-right">
                                <h3 className="inquiry_title mb-8">
                                    {intl.locale === "en" ? `Inquiry Form` : `Kontaktfomular`}
                                </h3>
                                <h3 className="h3">
                                    {intl.locale === "en"
                                        ? `Let us know about you:`
                                        : `Lassen Sie uns mehr über Sie und Ihr Anliegen wissen`}
                                </h3>
                                <p className="mb-8">
                                    {intl.locale === "en"
                                        ? `Please fill in all the fields that are indicated as mandatory.`
                                        : `Bitte füllen Sie alle gekennzeichneten Pflichtfelder aus`}
                                </p>

                                <HubspotForm />  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default PageContact;
